export const outOfStockMessages = {
  GB: "Colour / size out of stock",
  US: "Colour / size out of stock",
  AU: "Colour / size out of stock",
  FR: "Couleur / taille épuisée",
  DE: "Farbe / Größe ausverkauft",
  ES: "Color/talla no disponible",
  IT: "Colore / taglia non disponibile",
  RU: "Цвета/размера нет в наличии",
  ROW: "Colour / size out of stock",
  ROE: "Colour / size out of stock",
  default: "Colour / size out of stock",
};
