export const createSidebarListItem = (item, index) => {
  const { hostname } = new URL(window.location.href);
  return `
    <li id="article_${index}">
      <div class="article">
        <a
          href="https://${hostname}/${item.LinkUrl}"
          target="_self"
          rel="nofollow"
        >
          <div class="image">
            <img
              src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 300 300'%2F%3E"
              width="300"
              height="300"
              data-src="${
                item.ImageSource.includes("images.asos-media.com")
                  ? `${item.ImageSource}?$n_640w$&wid=513&fit=constrain`
                  : item.ImageSource
              }"
              alt=""
            />  
          </div>  
          <div class="caption">
            <p class="franchise">${item.Franchise}</p>
            <div class="wrapper">
              <h3>${item.Title}</h3>
              <p>${item.OverviewText}</p>
              <p class="date">${item.FormatedDate}</p>
            </div>
          </div>
        </a>
      </div>
    </li>
  `;
};
