import { formatDate } from "./formatDate";
import { getShowCategory } from "./getShowCategory";
import { setArticlesToLocalStorage } from "./setArticlesToLocalStorage";
import { getArticleCount } from "./getArticleCount";

export const handleData = (articles, element) => {
  setArticlesToLocalStorage(articles);

  articles.forEach((article) => {
    article.FormatedDate = !article.FormatedDate
      ? formatDate(article.Date)
      : article.FormatedDate;
    article.Franchise = !article.Franchise
      ? article.Category
      : article.Franchise;
    article.showCategory = getShowCategory(element);
  });

  const slicedArticles = articles.slice(0, getArticleCount(element));

  return slicedArticles;
};
