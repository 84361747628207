import { createCarousel } from "../carousel/utils/createCarousel";
import { createCta } from "./html-elements/cta";
import { callApi } from "./utils/callApi";
import { getOptions } from "./utils/getOptions";

export const ProductContainer = async (element) => {
  const options = getOptions(element);
  const products = await callApi(options);
  const headline = `<h2 style="color: ${options.headlineColour
    }">${options.headline.toUpperCase()}</h2>`;
  const carouselConfig = {
    type: options.type === "grid" ? "grid" : "carousel",
    root: options.type === "grid" ? "product-container-grid" : "carousel",
    rootAriaLabel: options.type === "grid" ? `Product grid` : "Carousel",
    headline,
    next: {
      buttonColor: options.buttonColour,
    },
    prev: {
      buttonColor: options.buttonColour,
    },
    cta: options.ctaText ? createCta(options, products) : "",
    maxPageSize: 4,
    hideDots: options.type === "grid",
    mobilePageSize: 2,
    previewNextPagePercentage: 0.25,
  };
  element.innerHTML = createCarousel(products, carouselConfig);
  element.classList.add("no-spin");
};
