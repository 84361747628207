export const generateViewAll = (data) => `
  <li class="carousel__item viewAll">
    <a
      class="js-buttonHover"
      href="${data.href}"
      style="${data.style}"
      data-hover-color=${data.hoverColor}
      data-hover-border=${data.hoverBorder}
      data-hover-background=${data.hoverBackground}
    >
      <span>${data.text}</span>
    </a>
  </li>
`;
