const addDotsToNav = (amount, color) => {
  let listItems = "";
  for (let i = 0; i < amount; i++) {
    listItems += `<li class="nav-dot ${
      i === 0 ? "active" : ""
    }" id="nav-dot-${i}" data-dot-index=${i} aria-setsize="${amount}" aria-posinset="${
      i + 1
    }" ${i === 0 ? 'aria-current="true"' : ""} ${
      color ? `style="background: ${color}"` : ""
    }>
    <button>
      <span class="sr-only">Go to page ${i + 1} of ${amount}</span>
    </button>
    </li>`;
  }
  return listItems;
};

export const createDots = (products, pageSize, color) => {
  const pages = Math.ceil(products.length / pageSize);
  return `<nav class="nav-dots" data-page-size="${pageSize}">${addDotsToNav(
    pages,
    color
  )}</nav>`;
};
