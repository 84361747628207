import { createCarousel } from "../carousel/utils/createCarousel";
import { createCategoryListItem } from "./utils/createCategoryListItem";

export const ProductCategories = async (element) => {
  if (!element.querySelector(".carousel")) return;
  const categories = element.querySelectorAll("ul li");
  const categoriesArray = Array.from(categories);
  const carousel = element.querySelector(".carousel");
  const maxPageSize = parseInt(
    element.querySelector(".js-carousel").classList.value.match(/\d/g).join()
  );

  const options = {
    type: "carousel",
    rootAriaLabel: "Carousel",
    prev: element.querySelectorAll(".carousel__controls")[0].dataset,
    next: element.querySelectorAll(".carousel__controls")[1].dataset,
    maxPageSize,
    mobilePageSize: 2,
    previewNextPagePercentage: 0.25,
    listItemFunc: createCategoryListItem,
  };

  carousel.classList.remove(
    `carouselItems-${carousel.classList.value.match(/\d/g).join()}`
  );

  carousel.innerHTML = createCarousel(categoriesArray, options);
  carousel.classList.add("no-spin");
};
