export const getMobileOrDesktop = (width) => {
  const breakpoint = 736;
  if (width >= breakpoint) {
    const offset = 40;
    const copySelector = ".desktop-bottom";
    const slugSelector = ".hero-slug";
    return [offset, copySelector, slugSelector];
  } else {
    const offset = 32;
    const copySelector = ".mobile-bottom";
    const slugSelector = ".hero-slug-mobile";
    return [offset, copySelector, slugSelector];
  }
};
