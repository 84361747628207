// This module is for applying responsive images in Sitecore.
// Add a desktop, mobile and breakpoint data attr to the img

export const ResponsiveImages = (element) => {
  const mobileImage = element.dataset.mobile;
  const desktopImage = element.dataset.desktop;
  const breakpoint = element.dataset.breakpoint;

  const setImage = () => {
    const src =
      window.innerWidth < breakpoint && mobileImage
        ? mobileImage
        : desktopImage;
    element.setAttribute("src", src);
  };

  window.addEventListener("resize", setImage);
};
