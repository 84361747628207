export const orderProducts = (productApiJson, productIdList) => {
  const inStockProducts = [];

  productApiJson.forEach((product) => {
    if (product.isInStock) {
      inStockProducts.push(product);
    }
  });

  inStockProducts.sort((a, b) => {
    return productIdList.indexOf(a.id) - productIdList.indexOf(b.id);
  });

  return inStockProducts;
};
