import configureLogger from "asos-web-logging";
import { configureRequest } from "@asosteam/asos-web-request";
import { handleApiResponse } from "./handleApiResponse";
import { handleError } from "./handleError";
import pkg from "../../../../package.json";

export const callApi = async (url, element) => {
  const logger = configureLogger({
    moduleName: "get-articles",
    maxLogLevel: "info",
  });
  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-web-ui-library",
      version: pkg.version,
      platform: "Web",
    },
  });

  try {
    const { data } = await request.get({
      url,
    });

    return handleApiResponse(data, element);
  } catch (error) {
    return handleError(element);
  }
};
