import React from "react";
import { APP } from "@asosteam/asos-web-adverts-sdk";
import Advert from "@asosteam/asos-web-adverts-display";

export const AdvertContainer = ({ placement, sdk, category }) => {
  return (
    <Advert
      sdkInstance={sdk}
      app={APP.HOMEPAGE}
      placement={placement}
      requestSponsoredProducts={false}
      requestDisplayAdverts={true}
      parameters={{
        category,
      }}
      rootMargin="500px"
    />
  );
};
