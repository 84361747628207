import { isLazyLoadSupported } from "./is-lazy-load-supported"; 
import { isElementInViewport } from "./is-element-in-viewport";

export const lazyLoad = () => {
  const lazyImages = document.querySelectorAll("img[data-src]");
  const lazyBackgrounds = document.querySelectorAll("[data-bg]");
  const lazyLoadSupported = isLazyLoadSupported();

  if(lazyLoadSupported) {
    lazyImages && lazyImages.forEach(image => {
      image.src = image.dataset.src;

      if(!isElementInViewport(image)) image.loading = "lazy";

      const img = new Image();

      img.onload = () => {
        image.classList.add("lazy-loaded");
        if (image.parentElement.classList.contains("carousel__imageWrap")) {
          image.parentElement.classList.add("no-spinner");
        }
      };

      img.src = image.src;

      delete(image.dataset.src);
    });
  } else {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.src = entry.target.dataset.src;

          const img = new Image();

          img.onload = () => {
            entry.target.classList.add("lazy-loaded");
            if (entry.target.parentElement.classList.contains("carousel__imageWrap")) {
              entry.target.parentElement.classList.add("no-spinner");
            }
          };

          img.src = entry.target.src;

          delete(entry.target.dataset.src);
          observer.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: "1250px",
    });

    lazyImages.forEach(image => {
      observer.observe(image);
    });
  }

  lazyBackgrounds && lazyBackgrounds.forEach(background => {
    background.style.backgroundImage = `url(${background.dataset.bg})`;
    delete(background.dataset.bg);
  });
}
