import { handleData } from "./handleData";
import { handleError } from "./handleError";

export const handleApiResponse = (data, element) => {
  if (data.items) {
    return handleData(data.items, element);
  } else {
    return handleError(element);
  }
};
