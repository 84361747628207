export const setDynamicBackgroundImage = (element) => {
  const desktopBackgroundImage = element.dataset.desktopBgimage || "";
  const mobileBackgroundImage = element.dataset.mobileBgimage || "";

  window.addEventListener("resize", setBackgroundImage);

  function setBackgroundImage() {
    const { innerWidth } = window;
    const isDesktop = innerWidth >= 736;
    const imageUrl = isDesktop ? desktopBackgroundImage : mobileBackgroundImage;

    element.style.backgroundImage = "url('" + imageUrl + "')";
    element.classList.add('lazy-loaded');
  }

  setBackgroundImage();
};
