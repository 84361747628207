export const getOptions = (element) => {
  let type = "carousel";
  if (element.classList.contains("productcontainer-grid")) {
    type = "grid";
  }

  const defaults = {
    "data-backgroundColour": "#FFFFFF",
    "data-maxUnitsToDisplay": 8,
    "data-descriptionColour": "#000000",
    "data-headlineColour": "#000000",
    "data-ctaBorderColour": "#000000",
    "data-ctaHoverBorderColour": "#000000",
    "data-ctaFillColour": "#000000",
    "data-ctaHoverFillColour": "#FFFFFF",
    "data-ctaTarget": "_self",
    "data-ctaColour": "#000000",
    "data-ctaHoverColour": "#000000",
    "data-buttonColour": "#ff5400",
    "data-path": window.location.origin,
    "data-additional-query-string-parameters": "",
    "data-mobile-page-size": "",
  };

  const getValOrDefault = (key) => {
    key = key.toLowerCase();
    if (
      element.attributes[key] &&
      element.attributes[key].value !== undefined
    ) {
      return element.getAttribute(key);
    } else {
      return defaults[key];
    }
  };

  return {
    type: type,
    backgroundColour: getValOrDefault("data-backgroundColour"),
    maxUnitsToDisplay: getValOrDefault("data-maxUnitsToDisplay"),
    categoryId: element.getAttribute("data-categoryid"),
    headline: element.getAttribute("data-headline"),
    headlineColour: getValOrDefault("data-headlineColour"),
    testApiUrl: element.getAttribute("data-testapiurl"),
    path: `/api`,
    buttonColour: getValOrDefault("data-buttonColour"),
    ctaBorderColour: getValOrDefault("data-ctaBorderColour"),
    ctaHoverBorderColour: getValOrDefault("data-ctaHoverBorderColour"),
    ctaFillColour: getValOrDefault("data-ctaFillColour"),
    ctaHoverFillColour: getValOrDefault("data-ctaHoverFillColour"),
    ctaColour: getValOrDefault("data-ctaColour"),
    ctaHoverColour: getValOrDefault("data-ctaHoverColour"),
    ctaText: element.getAttribute("data-ctaText"),
    ctaTarget: getValOrDefault("data-ctaTarget"),
    additionalQueryStringParameters: getValOrDefault("data-additional-query-string-parameters"),
    mobilePageSize: 2,
  };
};
