import { setResponsiveImage } from "../../../utilities/set-image";

export const createArticleListItem =
  (showDate, showCategory) => (item, index, element) => {
    const { hostname } = new URL(window.location.href);
    const { bind } = element
      .querySelector(".articleFeedItem")
      .querySelector("a").dataset;

    let ctaRef = "";
    if (bind) {
      ctaRef = bind.split("=")[1].split("'")[0];
    }
    return `
    <li class="carousel__item" id="article_${index}">
      <a
        href="https://${hostname}${item.LinkUrl}#ctaref-${ctaRef}"
        target="_self"
        rel="nofollow"
      >
        <div class="carousel__image__group">
          ${setResponsiveImage({
            imageUrl: item.ImageSource,
          })}
        </div>  
        <div class="carousel__label">
          ${
            showCategory
              ? `<label class="article__category">${item.Franchise}</label>`
              : ""
          }
          <label class="article__title">${item.Title}</label>
          <label class="article__overview">${item.OverviewText}</label>
          ${
            showDate
              ? `<label class="article__date">${item.FormatedDate}</label>`
              : ""
          }
        </div>
      </a>
    </li>
  `;
  };
