export const makeDotsInteractive = (
  nav,
  carouselContent,
  previewNextPagePercentage = 0,
  newPageSize
) => {
  const delta = Number(newPageSize);
  const dots = Array.from(nav.children);
  dots.forEach((dot) => {
    dot.querySelector("button").addEventListener("click", () => {
      const itemWidth = carouselContent.clientWidth / delta;
      if (previewNextPagePercentage > 0 && window.innerWidth < 737) {
        carouselContent.scrollTo(
          itemWidth * dot.getAttribute("data-dot-index") * (newPageSize - previewNextPagePercentage),
          0
        );
      } else {
        carouselContent.scrollTo(
          itemWidth * dot.getAttribute("data-dot-index") * (newPageSize),
          0
        );
      }
    });
  });
};
