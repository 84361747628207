export const setButtonHoverColor = (element) => {
  const defaultColor = element.style.color;
  const defaultBorderColor = element.style.borderColor;
  const defaultBackgroundColor = element.style.backgroundColor;
  const hoverColor = element.dataset.hoverColor;
  const hoverBorderColor = element.dataset.hoverBorder;
  const hoverBackgroundColor = element.dataset.hoverBackground;

  element.addEventListener("mouseover", () => applyStyles(true));
  element.addEventListener("mouseout", () => applyStyles(false));

  function applyStyles(isMouseOver) {
    element.style.color = isMouseOver ? hoverColor : defaultColor;
    element.style.borderColor = isMouseOver ? hoverBorderColor : defaultBorderColor;
    element.style.backgroundColor = isMouseOver ? hoverBackgroundColor : defaultBackgroundColor;
  }
};
