export const getImageUrl = (product, productIdColourList) => {
  const imagesList = product.images;
  let img = imagesList.find((item) => {
    return productIdColourList[product.id] === item.colour;
  });

  if (!img) {
    img = imagesList.find((prod) => {
      return prod.isPrimary;
    });
  }
  return (img && img.url) || (imagesList.length && imagesList[0].url) || "";
};
