export const setDynamicBackgroundColor = (element) => {
  const desktopBackgroundColor = element.dataset.desktopBgcolor || "transparent";
  const mobileBackgroundColor = element.dataset.mobileBgcolor || "transparent";

  window.addEventListener("resize", setBackgroundColor);

  function setBackgroundColor() {
    const { innerWidth } = window;
    const isDesktop = innerWidth >= 1024;
    const backgroundColor = isDesktop ? desktopBackgroundColor : mobileBackgroundColor;

    element.style.backgroundColor = backgroundColor;
  }

  setBackgroundColor();
};
