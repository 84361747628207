import configureLogger from "asos-web-logging";
import { configureRequest } from "@asosteam/asos-web-request";
import pkg from "../../../../package.json";

export const callApi = async (options) => {
  const apiQueryParams = new URLSearchParams({
    channel: "desktop-web",
    country: window.asos.webContext.getBrowseCountry(),
    currency: window.asos.webContext.getBrowseCurrency(),
    keyStoreDataversion: window.asos.webContext.getKeyStoreDataversion(),
    lang: window.asos.webContext.getBrowseLanguage(),
    limit: options.maxUnitsToDisplay,
    offset: 0,
    rowlength: 4,
    store: window.asos.webContext.getStoreId(),
    tstSearchSponsoredProducts: true,
  });
  const url =
    options.testApiUrl ||
    `${options.path}/product/search/v2/categories/${options.categoryId}?${apiQueryParams}${options.additionalQueryStringParameters}`;

  const logger = configureLogger({
    moduleName: "get-products-by-category",
    maxLogLevel: "info",
  });
  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-web-ui-library",
      version: pkg.version,
      platform: "Web",
    },
  });

  try {
    const { data } = await request.get({
      url,
    });

    if (data.products) return data.products;
    return [];
  } catch (error) {
    console.log(error);

    return [];
  }
};
