export const getCategoryForCriteo = () => {
  const { floor } = window.asos.webContext.getWebContext();

  switch (floor) {
    case "men":
      return 1001;
    case "women":
      return 1000;
    default:
      return null;
  }
};
