import { getMobileOrDesktop } from "./getMobileOrDesktop";

export const updateBottomAlignment = (element) => {
  const width = window.innerWidth;
  const [offset, copySelector, slugSelector] = getMobileOrDesktop(width);
  const copyElement = element.querySelector(copySelector);

  if (copyElement) {
    const slugElement = element.querySelector(slugSelector);
    if (slugElement) {
      const slugHeight = slugElement.getBoundingClientRect().height;
      const totalOffset = offset + slugHeight;
      copyElement.style.bottom = `${totalOffset}px`;
    }
  }
};
