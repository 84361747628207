const sdkExists = (sdkName) => window.asos.sdk && window.asos.sdk[sdkName];

export const sdkProvider = (sdkName) => {
  return new Promise((resolve) => {
    const resolveSdk = () => {
      if (sdkName === "features") {
        window.asos.sdk.features.onReady().then(() => {
          return resolve(window.asos.sdk.features);
        });
      } else {
        const sdk = window.asos.sdk[sdkName];
        resolve(sdk);
      }
    };

    if (sdkExists(sdkName)) return resolveSdk();

    window.asos.eventBus.once(`${sdkName}-sdk-ready`, resolveSdk);
  });
};
