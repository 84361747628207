import { analyticsEventTrigger } from "./analyticsEventTrigger";
import { videoAnalytics } from "./video-event-analytics";

export const pageLoadAnalytics = (analyticsConfig, customerId) => {
  const siteChrome = window.asos.webContext.getWebContext();

  if (analyticsConfig.siteSection === "article") {
    const analyticsAttribute = document.getElementById("analytics");
    const gender = window.location.href.split("/")[3];
    const dataPlatform = document.querySelector("html");
    const siteChrome = window.asos.webContext.getWebContext();

    if (
      analyticsConfig.articleTitle &&
      analyticsConfig.articleTitle.length > 0
    ) {
      // article page tracking
      analyticsEventTrigger("tracking.articles.pageLoad", {
        platform: dataPlatform.getAttribute("data-platform"),
        "store ID": siteChrome.storeId,
        "site section": analyticsConfig.siteSection,
        "page title": analyticsAttribute.getAttribute("data-pagetitle"),
        "article title": analyticsConfig.articleTitle,
        "article ID": analyticsConfig.articleID,
        "article category": analyticsConfig.articleCategory,
        "article type": analyticsConfig.articleType,
        "page type": analyticsAttribute.getAttribute("data-pagetype"),
        "customer ID": customerId,
        gender: gender,
        countryIso: siteChrome.browseCountry,
        currency: siteChrome.browseCurrency,
        author: analyticsConfig.articleAuthor,
      });
    } else {
      // archive page tracking
      analyticsEventTrigger("tracking.articles.pageLoad", {
        platform: dataPlatform.getAttribute("data-platform"),
        "store ID": siteChrome.storeId,
        "site section": analyticsConfig.siteSection,
        "page name": gender,
        "page title": analyticsAttribute.getAttribute("data-pagetitle"),
        "page type": analyticsAttribute.getAttribute("data-pagetype"),
        "customer ID": customerId,
        gender: gender,
        countryIso: siteChrome.browseCountry,
        currency: siteChrome.browseCurrency,
      });
    }
  } else {
    analyticsEventTrigger(`tracking.${analyticsConfig.eventName}.pageLoad`, {
      platform: document.querySelector("html").getAttribute("data-platform"),
      storeID: siteChrome.storeID,
      floor: document
        .getElementById("analytics")
        .getAttribute("data-pagetitle"),
      language: siteChrome.browseLanguage,
      customerID: customerId,
      elementsList: analyticsConfig.elementsList,
      socialPlatformsList: analyticsConfig.socialPlatformsList,
      countryIso: siteChrome.browseCountry,
      currency: siteChrome.browseCurrency,
    });

    videoAnalytics(analyticsConfig, customerId);
  }

  return analyticsConfig;
};
