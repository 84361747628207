export const toggleDot = (nav, index) => {
  const dots = Array.from(nav.children);
  dots.forEach((dot) => {
    dot.classList.remove("active");
    dot.removeAttribute("aria-current");
  });
  const dot = dots[index];
  dot.setAttribute("aria-current", "true");
  dot.classList.add("active");
};
