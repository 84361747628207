import { orderProducts } from "./orderProducts";
import { getImageVariant } from "./getImageVariant";
import { setOutOfStockMessage } from "./setOutOfStockMessage";
import { getImageUrl } from "./getImageUrl";
import { setAriaLabel } from "./setAriaLabel";
import { getProductUrl } from "./getProductUrl";

export const handleApiData = (
  productApiJson,
  { productIdList, productIdColourList, baseUrl }
) => {
  const orderedProducts = orderProducts(productApiJson, productIdList);

  orderedProducts.forEach((product) => {
    const productVariant = getImageVariant(product, productIdColourList);

    product.imageUrl = getImageUrl(product, productIdColourList);

    if (product.price.current.value < product.price.previous.value) {
      product.price.previous.displayText = product.price.previous.text;
    }

    if (
      product.price.current.value > product.price.previous.value &&
      product.price.current.value < product.price.rrp.value
    ) {
      product.price.previous.displayText = product.price.rrp.text;
    }

    product.ariaLabel = setAriaLabel(product);

    product.productUrl = getProductUrl(baseUrl, product.id, productVariant);
  });

  return orderedProducts;
};
