export const throttle = (callback, wait = 100) => {
  let timeoutId = null;
  return (...args) => {
    if (timeoutId === null) {
      timeoutId = setTimeout(() => {
        callback.apply(null, args);
        timeoutId = null;
      }, wait);
    }
  };
};
