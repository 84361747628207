const wrapButton = (visible, attributes, color) => `
<div
    class="carousel__controls ${!visible ? "invisible" : ""}"
    >
  <button ${attributes}>
    <div style="background-color: ${color}" />
  </button>
</div>
`;

const renderButton = (type, attributes, color) =>
  wrapButton(type === "carousel", attributes, color);

export const nextCarouselButton = (type, color = "black") =>
  renderButton(type, `class="next-page js-next" aria-label="next"`, color);

export const previousCarouselButton = (type, color = "black") =>
  renderButton(
    type,
    `class="previous-page js-previous" aria-label="previous"`,
    color
  );
