import configureLogger from "asos-web-logging";
import { configureRequest } from "@asosteam/asos-web-request";
import pkg from "../../../../package.json";

const makeApiCallURL = ({ categoryId, store, lang,  sortType, mediaType, includeOutOfStock, limit }) => {
  return `/api/marketing/user-generated-content/v1/categories/${categoryId}/media?store=${store}&lang=${lang}${sortType ? `&sortType=${sortType}` : "" }${mediaType ? `&mediaType=${mediaType}` : "" }${includeOutOfStock ? `&includeOutOfStock=${includeOutOfStock}` : "" }${limit ? `&limit=${limit}` : "" }`;
  };
  
export const callApi = async (options) => {
  
    const context = window?.asos && window.asos.webContext;
    const webContext = {
      language: context.getBrowseLanguage(),
      storeId: context.getStoreId(),
    };
  
  const url =
    options.testApiUrl ||
    makeApiCallURL({
      categoryId: options.categoryId,
      lang: webContext.language,
      store: webContext.storeId,
      sortType: options.sortType,
      mediaType: options.mediaType,
      includeOutOfStock: "false",
      limit: 12,
    });

  const logger = configureLogger({
    moduleName: "get-products-by-category",
    maxLogLevel: "info",
  });

  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-web-ui-library",
      version: pkg.version,
      platform: "web",
    },
  });
  
  try {
    const result = await request.get({
      url,
    });

    if (result.data?.media)
      return result.data.media;
  } catch (error) {
    console.log(error);
    return [];
  }
};
