import { analyticsEventTrigger } from "../../utilities/analyticsEventTrigger";

window.__CX = window.__CX || {};
window.__CX.video = window.__CX.video || document.createElement("div");

const mediaElementIsActive = "mediaElementIsActive";

/**
 * Optimised scroll event listener
 * @param {Object} element Element to listen to
 * @param {Function} callback
*/
function eventOnScroll(element, callback) {
  let throttle = false;

  element.addEventListener(
    "scroll",
    function (e) {
      if (!throttle) {
        const scrollPosition =
          window.scrollY ||
          window.pageYOffset ||
          document.documentElement.scrollTop;

        if (window.requestAnimationFrame) {
          window.requestAnimationFrame(function () {
            callback(scrollPosition, e);
            throttle = false;
          });

          throttle = true;
        } else {
          callback(scrollPosition, e);
          throttle = true;

          setTimeout(function () {
            throttle = false;
          }, 100);
        }
      }
    },
    false
  );
}

/**
 * Optimised click and tap event listener
 * @param {Object} element Element to listen to
 * @param {Function} callback
*/
function eventOnClickOrKeydown(element, callback) {
  let lastEventTimeStamp;
  const spacebarKeyNames = [" ", "Spacebar"]; //IE11

  const handleEvent = function (e) {
    if (e.timeStamp && e.timeStamp !== lastEventTimeStamp) {
      lastEventTimeStamp = e.timeStamp;
      callback(e);
    }
  };
  /* Covert mouse clicks to touch events for touch users with a mouse*/  function touchClickHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    const simulatedEvent =
      "ontouchstart" in window && event.type === "mouseup"
        ? new TouchEvent("touchend")
        : event;
    handleEvent(simulatedEvent);
  }

  ["mouseup", "touchend", "keydown"].forEach(function (eventName) {
    element.addEventListener(
      eventName,
      function (e) {
        switch (eventName) {
          case "keydown":
            if (e.key === "Enter" || spacebarKeyNames.includes(e.key)) {
              handleEvent(e);
            }
            break;
          default:
            touchClickHandler(e);
        }
      },
      false
    );
  });
}

/**
 * Optimised hover/touch focus listener
 * @param {Object} element Element to listen to
 * @param {Function} callback
*/
function eventOnInFocus(element, callback) {
  let lastEventTimeStamp;
  const handleEvent = function (e) {
    if (e.timeStamp && e.timeStamp !== lastEventTimeStamp) {
      lastEventTimeStamp = e.timeStamp;
      callback(e);
    }
  };

  ["touchstart", "mouseenter", "mousemove", "keydown", "focus", "blur"].forEach(
    function (eventName) {
      element.addEventListener(
        eventName,
        function (e) {
          switch (eventName) {
            /* ignore e.timeStamp for touch devices using a mouse*/            case "mouseenter":
            case "mousemove":
              "ontouchstart" in window ? callback(e) : handleEvent(e);
              break;
            default:
              handleEvent(e);
          }
        },
        true
      );
    }
  );
}

/**
 * Check if the browser is in fullscreen mode
*/
function isFullScreen() {
  return !!(
    document.fullScreen ||
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement
  );
}

/**
 * Media query
*/
function mediaQueryMatch(query) {
  if (window.matchMedia) {
    return window.matchMedia(query).matches;
  }

  return false;
}

/**
 * Convert seconds to minutes and seconds
*/
function secondsToTimeString(seconds) {
  let minutes = 0;
  let hours = 0;

  if (seconds / 60 > 0) {
    minutes = parseInt(seconds / 60, 10);
    seconds = seconds % 60;
  }

  if (minutes / 60 > 0) {
    hours = parseInt(minutes / 60, 10);
    minutes = minutes % 60;
  }

  let time = ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);

  if (hours) {
    time = ("0" + hours).slice(-2) + ":" + time;
  }

  return time;
}

/**
 * Calculates offset on mobile
 */
function calculateOffset(event) {
  return (
    event.changedTouches.length &&
    event.changedTouches[0].clientX -
      (window.innerWidth - event.currentTarget.offsetWidth) / 2
  );
}

/**
 * Creates holding image when video is not supported.
 */
function createHoldingImage(container, src, wrapper) {
  const img = document.createElement("img");

  img.src = src;
  img.className = "video-holdingImage";
  container.classList.add("video-no-support");
  wrapper && wrapper.classList.add("video-no-support");
  container.appendChild(img);
}

export class VideoClickToPlay {
  constructor(options) {
    this.wrapper = options.wrapper;
    this.container = options.container;
    this.srcPrimary = options.srcPrimary;
    this.srcSecondary =
      options.srcSecondary ||  options.srcPrimary;
    this.posterPrimary = options.posterPrimary;
    this.posterSecondary =
      options.posterSecondary ||
       options.posterPrimary;
    this.errorMessage = options.errorMessage;
    this.breakpoint = options.breakpoint;
    this.classNames = options.classNames;
    this.playbackSettings =
      options.playbackSettings ||  [];
    this.controls = options.controls;
    this.autoplay = options.autoplay;
    this.player = document.createElement("video");
  }

  init() {
    if (
      this.player.canPlayType &&
      this.player.canPlayType("video/mp4") !== ""
    ) {
      this.setSrcAndPoster();
      this.setClassName();
      this.setPlaybackSettings();
      this.container.insertBefore(this.player, this.container.firstChild);

      if (this.autoplay) {
        this.onAutoEvents();
        this.toggleAutoplay();
      } else {
        this.player.preload = "metadata";
        this.provisionFullScreen();
        this.onClickEvents();
      }
    } else {
      const src = mediaQueryMatch(this.breakpoint)
        ? this.posterSecondary
        : this.posterPrimary;
      createHoldingImage(this.container, src, this.wrapper);
    }
  }

  sendAnalytics(videoState, playOption, pauseTime) {
    const videoTitle =
      this.wrapper.querySelector(".videoClickToPlay-header__title") &&
      this.wrapper.querySelector(".videoClickToPlay-header__title").textContent;

    analyticsEventTrigger("tracking.home.pageInteraction", {
      global: {},
      context: videoState,
      video: {
        playState: "manual",
        playOption: playOption,
        playLength:
          Math.floor(this.player.duration) ||
          Number(
            this.player.timeStringDuration &&
              this.player.timeStringDuration.replace(":", "")
          ),
        pauseTime: pauseTime,
        videoTitle: this.player.title.length
          ? this.player.title
          : videoTitle
          ? videoTitle.trim()
          : "",
        videoUrl: this.player.src,
      },
    });

    if (playOption === "pause") {
      window.addEventListener(
        "tracking.home.pageInteraction",
        function (event) {
          event.stopPropagation();
        }
      );
    }
  }

  onAutoEvents() {
    const self = this;

    eventOnScroll(window, function () {
      self.toggleAutoplay();
    });

    document.addEventListener(
      "visibilitychange",
      function () {
        if (document.visibilityState === "hidden") {
          self.player.pause();
        } else {
          self.toggleAutoplay();
        }
      },
      false
    );
  }

  onClickEvents() {
    const self = this;
    const orientation =
      window.screen.orientation ||
      window.screen.mozOrientation ||
      window.screen.msOrientation;

    if (self.controls.start) {
      eventOnClickOrKeydown(self.controls.start, function () {
        let isPlayingElList = document.querySelectorAll(".isPlaying video");
        isPlayingElList = Array.prototype.slice.call(isPlayingElList, 0);
        isPlayingElList.forEach(function (video) {
          video.pause();
        });
        self.activatePlayer();
        self.toggleVideoCropping();
        self.toggleErrorState();
        //commented out because this will send off too many events to adobe
        //self.sendAnalytics('videoPlay', 'new');
      });
    }

    eventOnScroll(window, function () {
      self.toggleAutoplay();
    });

    eventOnClickOrKeydown(self.controls.playPause, function () {
      self.togglePlayback();
      self.toggleVideoCropping();
    });

    eventOnClickOrKeydown(self.player, function () {
      if (self.isActive) {
        self.togglePlayback();
        self.toggleVideoCropping();
      }
    });

    eventOnClickOrKeydown(self.controls.volume, function () {
      self.toggleVolume();
    });

    eventOnClickOrKeydown(self.controls.progress, function (e) {
      const offset = e && (e.offsetX || calculateOffset(e));
      self.skipTo(offset);
    });

    eventOnInFocus(self.wrapper, function () {
      self.setFocusState();
    });

    document.addEventListener(
      "visibilitychange",
      function () {
        if (document.visibilityState === "hidden") {
          self.player.pause();
        } else {
          self.toggleAutoplay();
        }
      },
      false
    );

    orientation &&
      orientation.addEventListener(
        "change",
        function (e) {
          if (/isActive/.test(self.wrapper.className)) {
            if (/landscape/.test(orientation.type)) {
              self.wrapper.classList.add("landscape");
              document.body.classList.add("no-pointer-events");
              document.querySelector(".isActive").requestFullscreen();
            } else {
              self.wrapper.classList.remove("landscape");
              self.removePointerEvents();
              document.exitFullscreen();
            }
          }
        },
        false
      );

    self.player.addEventListener("loadedmetadata", function () {
      self.setProgressMax();
    });

    self.player.addEventListener("progress", function () {
      self.setCurrentBuffer();
    });

    self.player.addEventListener("canplaythrough", function () {
      self.setCurrentBuffer();
    });

    self.player.addEventListener("timeupdate", function () {
      if (!self.controls.progress.getAttribute("max")) {
        self.setProgressMax();
      }

      self.setCurrentBuffer();
      self.setCurrentProgress();
    });

    self.player.addEventListener("ended", function () {
      self.reset();
    });

    self.player.addEventListener("error", function (e) {
      const error = e.error || (e.target && e.target.error);
      if (self.container.getAttribute("data-reset") === "false") return;

      self.toggleErrorState(error);
    });

    if (self.wrapper.requestFullscreen) {
      eventOnClickOrKeydown(self.controls.fullScreen, function () {
        self.toggleFullScreenPlayer();
      });

      document.addEventListener(self.onFullScreenChange, function () {
        self.toggleFullScreenPresentation();
        self.toggleVideoCropping();
      });
    }

    window.__CX.video.addEventListener(mediaElementIsActive, function (e) {
      if (e.detail !== self.player) {
        self.pause();
        self.toggleVideoCropping();
      }
    });
  }

  setSrcAndPoster() {
    if (mediaQueryMatch(this.breakpoint)) {
      this.player.setAttribute("src", this.srcSecondary);
      this.player.setAttribute("poster", this.posterSecondary);
    } else {
      this.player.setAttribute("src", this.srcPrimary);
      this.player.setAttribute("poster", this.posterPrimary);
    }

    this.container.classList.add("video-loaded");
  }

  setClassName() {
    this.player.className = this.classNames;
  }

  setPlaybackSettings() {
    const self = this;

    self.player.controls = false;

    self.playbackSettings.forEach(function (setting) {
      self.player[setting] = true;
    });
  }

  setProgressMax() {
    this.player.timeStringDuration = secondsToTimeString(
      Math.floor(this.player.duration)
    );
    this.controls.progressTime.innerHTML = secondsToTimeString(0);
    this.controls.progressDuration.innerHTML = this.player.timeStringDuration;
    this.controls.progress.setAttribute("max", this.player.duration);
  }

  setCurrentBuffer() {
    /* istanbul ignore else*/    if (this.player.buffered.length) {
      this.controls.buffer.style.width =
        Math.floor((this.player.buffered.end(0) / this.player.duration) * 100) +
        "%";
    }
  }

  setCurrentProgress() {
    this.controls.progressTime.innerHTML = secondsToTimeString(
      Math.floor(this.player.currentTime)
    );
    this.controls.progressDuration.innerHTML = this.player.timeStringDuration;
    this.controls.progress.value = this.player.currentTime;
    this.controls.progressBar.style.width =
      Math.floor((this.player.currentTime / this.player.duration) * 100) + "%";
  }

  skipTo(position) {
    this.player.currentTime =
      (position / this.controls.progress.offsetWidth) * this.player.duration;
  }

  isOnScreen() {
    const playerBounds = this.container.getBoundingClientRect();
    if (playerBounds.top || playerBounds.bottom || playerBounds.height) {
      return (
        playerBounds.top >= -playerBounds.height &&
        playerBounds.bottom <= window.innerHeight + playerBounds.height
      );
    }

    return;
  }

  toggleAutoplay() {
    const playerOnScreen = this.isOnScreen();
    const playerPaused = this.player.paused;
    const isVideoClickToPlay =
      this.wrapper &&
      /videoClickToPlay-wrapper/.test(this.wrapper.className) &&
      /isActive/.test(this.wrapper.className) &&
      !/isPaused/.test(this.wrapper.className);

    if (isVideoClickToPlay && playerOnScreen && playerPaused) {
      this.player.play();
    } else if (
      /videoHero-player/.test(this.classNames) &&
      playerOnScreen &&
      playerPaused
    ) {
      this.player.play();
      //this.sendAnalytics('videoPlay', 'auto play', Math.round(this.player.currentTime));
    }

    /* istanbul ignore else*/    if (!playerOnScreen && !playerPaused) {
      this.player.pause();
    }
  }

  activatePlayer() {
    this.player.load();
    this.wrapper.classList.add("isActive");
    this.isActive = true;
    this.play();
  }

  togglePlayback() {
    if (this.player.paused) {
      this.play();
      this.wrapper.classList.remove("isPaused");
    } else {
      this.pause();
      this.wrapper.classList.add("isPaused");
      this.sendAnalytics(
        "videoPlay",
        "pause",
        Math.round(this.player.currentTime)
      );
    }
  }

  play() {
    this.player.play();
    this.wrapper.classList.add("isPlaying");
    this.player.tabIndex = 0;
    this.controls.playPause.innerHTML =
      this.controls.playPause.getAttribute("data-offText");
    this.scrollOnScreen();
    this.sendAnalytics(
      "videoPlay",
      "play",
      Math.round(this.player.currentTime)
    );
    if (typeof Event === "function") {
      var event = new CustomEvent(mediaElementIsActive, {
        detail: this.player,
      });
    } else {
      var event = document.createEvent("Event");
      event.initEvent(
        mediaElementIsActive,
        {
          detail: this.player,
        },
        true,
        true
      );
    }

    window.__CX.video.dispatchEvent(event);
  }

  scrollOnScreen() {
    if (!isFullScreen()) {
      this.wrapper.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  pause() {
    this.player.pause();
    this.wrapper.classList.remove("isPlaying");
    this.controls.playPause.innerHTML =
      this.controls.playPause.getAttribute("data-onText");
  }

  reset() {
    this.isActive = false;
    this.player.removeAttribute("tabindex");
    this.wrapper.classList.remove("isActive");
    this.wrapper.classList.remove("isPlaying");
    this.container.style.paddingBottom = null;
    this.controls.playPause.innerHTML =
      this.controls.playPause.getAttribute("data-onText");
    this.player.load();

    if (/mobileFullScreen/.test(this.wrapper.parentNode.className)) {
      this.disableMobileFullScreenMode();
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  toggleVolume() {
    if (this.player.muted) {
      this.wrapper.classList.remove("isMuted");
      this.player.muted = false;
      this.controls.volume.innerHTML =
        this.controls.volume.getAttribute("data-onText");
    } else {
      this.wrapper.classList.add("isMuted");
      this.player.muted = true;
      this.controls.volume.innerHTML =
        this.controls.volume.getAttribute("data-offText");
    }
  }

  provisionFullScreen() {
    const self = this;

    self.onFullScreenChange = "fullscreenchange";

    if (self.wrapper.msRequestFullscreen) {
      self.onFullScreenChange = "msfullscreenchange";
      self.wrapper.requestFullscreen = self.wrapper.msRequestFullscreen;
      document.exitFullscreen = document.msExitFullscreen;
    }

    if (self.wrapper.mozRequestFullScreen) {
      self.onFullScreenChange = "mozfullscreenchange";
      self.wrapper.requestFullscreen = self.wrapper.mozRequestFullScreen;
      document.exitFullscreen = document.mozCancelFullScreen;
    }

    if (self.wrapper.webkitRequestFullscreen) {
      self.onFullScreenChange = "webkitfullscreenchange";
      self.wrapper.requestFullscreen = self.wrapper.webkitRequestFullscreen;
      document.exitFullscreen = document.webkitCancelFullScreen;
    }

    if (self.wrapper.webkitEnterFullScreen) {
      self.onFullScreenChange = "webkitfullscreenchange";
      self.wrapper.requestFullscreen = self.wrapper.webkitEnterFullScreen;
      document.exitFullscreen = document.webkitCancelFullScreen;
    }

    if (!self.wrapper.requestFullscreen && self.player.webkitEnterFullScreen) {
      self.wrapper.requestFullscreen = function () {
        if (/mobileFullScreen/.test(self.wrapper.parentNode.className)) {
          self.disableMobileFullScreenMode();
        } else {
          self.enableMobileFullScreenMode();
        }
      };
    }
  }

  toggleFullScreenPlayer() {
    if (isFullScreen()) {
      document.exitFullscreen();
      this.removePointerEvents();

      if (/mobileFullScreen/.test(this.wrapper.parentNode.className)) {
        this.disableMobileFullScreenMode();
      }
    } else {
      if (mediaQueryMatch(this.breakpoint)) {
        document.body.classList.add("no-pointer-events");
      }

      this.wrapper.requestFullscreen();
    }
  }

  removePointerEvents() {
    setTimeout(function () {
      document.body.classList.remove("no-pointer-events");
    }, 1000);
  }

  enableMobileFullScreenMode() {
    this.wrapper.parentNode.classList.add("mobileFullScreen");
    this.wrapper.classList.add("isFullScreen");
    document.body.classList.add("no-pointer-events");
  }

  disableMobileFullScreenMode() {
    this.wrapper.parentNode.classList.remove("mobileFullScreen");
    this.wrapper.classList.remove("isFullScreen");
    this.removePointerEvents();
  }

  toggleFullScreenPresentation() {
    if (isFullScreen()) {
      this.wrapper.classList.add("isFullScreen");
      this.controls.fullScreen.innerHTML =
        this.controls.fullScreen.getAttribute("data-offText");
    } else {
      this.wrapper.classList.remove("isFullScreen");
      if (/mobileFullScreen/.test(this.wrapper.parentNode.className)) {
        this.disableMobileFullScreenMode();
      }
      this.controls.fullScreen.innerHTML =
        this.controls.fullScreen.getAttribute("data-onText");
    }
  }

  toggleVideoCropping() {
    if (mediaQueryMatch(this.breakpoint)) {
      this.container.style.paddingBottom = null;
    } else {
      const isPortrait = this.player.clientHeight > window.innerHeight;
      if (this.player.paused || isFullScreen() || isPortrait) {
        this.container.style.paddingBottom = null;
      } else {
        this.container.style.paddingBottom =
          this.player.getBoundingClientRect().height + "px";
      }
    }
  }

  setFocusState() {
    const self = this;

    /* istanbul ignore else*/    clearTimeout(self.controlsTimer);
    self.wrapper.classList.add("isInFocus");

    
    self.controlsTimer = setTimeout(function () {
      self.wrapper.classList.remove("isInFocus");
    }, 3000);
  }

  toggleErrorState(error) {
    if (!this.controls.start) return;

    if (error && this.controls.start) {
      this.controls.start.querySelector("span").innerHTML =
        this.controls.start.getAttribute("data-errorMessage");
      this.wrapper.classList.add("isError");
      this.errorMessage.innerHTML =
        this.errorMessage.getAttribute("data-errorMessage");
    } else {
      this.controls.start.querySelector("span").innerHTML =
        this.controls.start.getAttribute("data-text");
      this.wrapper.classList.remove("isError");
      this.errorMessage.innerHTML = null;
    }
  }
}
