import { renderIfItemsExist } from "./renderIfItemsExist";
import { previousCarouselButton, nextCarouselButton } from "./createButtons";
import { createCarouselListItem } from "./createCarouselListItem";
import { carouselInstructions } from "./carouselInstructions";

export const createCarousel = (items, options, element) => {
  return `
    ${options.headline ? options.headline : ""}
    ${options.root ? `<div class="${options.root}" role="region" aria-label="${options.rootAriaLabel}">` : ""}
    ${options.type === "carousel" ? `<div class="sr-only">${carouselInstructions}</div>`: ""}
    ${renderIfItemsExist(
    items,
    `
      <div class='carousel__content__wrapper carouselItems-${
          options.maxPageSize
        }' data-mobile-page-size="${
          options.mobilePageSize ? options.mobilePageSize : ""
        }" data-preview-next="${
          options.previewNextPagePercentage ? options.previewNextPagePercentage : ""
        }">
          ${previousCarouselButton(
          options.gridMode ? "grid" : "carousel",
          options.prev?.buttonColor
        )}
        <ul class='carousel__content'>   
          ${items
            .map((item, index) =>
              options.listItemFunc
                ? options.listItemFunc(item, index, element)
                : createCarouselListItem(item, index, element)
            )
            .join("")}
          ${options.extraListItem ? options.extraListItem : ""}
        </ul>
        ${nextCarouselButton(
          options.gridMode ? "grid" : "carousel",
          options.next?.buttonColor
        )}
      </div>
      ${options.hideDots ? "" : `<nav class="nav-dots" />`}
    `
    )}
    ${options.root ? `</div>` : ""}
    ${options.cta ? options.cta : ""}
  `;
};
