export const shiftFocus = (entry, listItemToFocus, nav) => {
  if (!entry.isIntersecting) return;
  if (
    listItemToFocus &&
    entry.target.querySelector("a") === listItemToFocus &&
    (document.activeElement.closest(".carousel") !== null ||
      document.activeElement.closest(".productcontainer-carousel") !== null ||
      document.activeElement.closest(".article-feed") !== null ||
      document.activeElement.closest(".nav-dots") !== null)
  ) {
    listItemToFocus.focus({ preventScroll: true });
  }
};
