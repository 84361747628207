let interactionListeners;

const getViewportDimensions = () => {
  const height = window.innerHeight || "";
  const width = window.innerWidth || "";
  return typeof width === "number" && typeof height === "number"
    ? width + ":" + height
    : "";
};

const interactionHandler = (analyticsConfig, customerId, event) => {
  if (window.s) {
    const data = event.detail;

    const analyticsCommons = new CommonAnalytics({});
    s = s_gi(s.account);
    let analyticsOverrides = {},
      customLinkName,
      analyticsAttribute = document.getElementById("analytics");

    analyticsOverrides.linkTrackVars = analyticsCommons.setLinkTrackVars([
      "eVar1",
      "eVar10",
      "eVar17",
      "eVar19",
      "eVar29",
      "eVar30",
      "eVar47",
      "eVar60",
      "eVar61",
      "eVar112",
      "eVar113",
      "eVar15",
      "eVar12",
      "eVar111",
      "eVar28",
      "eVar6",
      "eVar20",
      "prop1",
      "prop2",
      "prop3",
      "prop5",
      "prop8",
      "prop10",
      "prop11",
      "prop25",
      "prop31",
      "prop39",
      "prop6",
      "prop40",
    ]);
    analyticsOverrides.linkTrackEvents = analyticsCommons.setLinkTrackEvents([
      "event117",
      "event120",
    ]);

    if (window.__trackingData) {
      const legacy = __trackingData.legacyData;
    }
    const webContext = window.asos.webContext.getWebContext();

    context = {
      browseLanguage: webContext.browseLanguage,
      browseStore: webContext.storeId,
      browseCountry: webContext.browseCountry,
      browseCurrency: webContext.browseCurrency,
      platform: webContext.platform,
      floor: webContext.floor,
      identityState: null,
      loginStatus: null,
      guid: null,
      navApi: window.performance.timing,
    };

    s.pageName =
      context.platform +
      " " +
      context.browseStore.toLowerCase() +
      "| floor page |" +
      context.floor.toLowerCase();
    s.channel = context.floor !== "home" ? context.floor : "home page ";
    s.server = (context.platform + " " + context.browseStore).toLowerCase();

    s.eVar1 = s.getVisitNum();
    s.eVar10 = customerId;
    s.eVar17 = typeof s.getNewRepeat === "function" ? s.getNewRepeat(365) : "";
    s.eVar19 = getViewportDimensions();
    s.eVar29 = context.navApi.responseStart - context.navApi.navigationStart;
    s.eVar30 =
      context.navApi.domContentLoadedEventEnd - context.navApi.navigationStart;
    s.eVar60 = s.getQueryParamValue("cid", true);
    s.eVar61 = s.getQueryParamValue("cid", true);
    s.eVar112 = context.browseCountry.toLowerCase();
    s.eVar113 = context.browseCurrency.toLowerCase();
    s.eVar15 = analyticsAttribute.getAttribute("data-pagetype").toLowerCase();
    s.eVar28 = encodeURI(window.location.href.replace(/%20/gi, "+"));
    s.eVar12 = s.getQueryParamValue("affid") || s.getQueryParamValue("xaffid");
    s.eVar6 = s.eVar6;
    s.eVar20 = context.browseStore.toLowerCase();

    s.prop1 = analyticsAttribute.getAttribute("data-breadcrumb").toLowerCase();
    s.prop2 = analyticsAttribute.getAttribute("data-breadcrumb").toLowerCase();
    s.prop3 = analyticsAttribute.getAttribute("data-breadcrumb").toLowerCase();
    s.prop5 = s.getCustomTimeDate().toString().toLowerCase();
    s.prop8 = analyticsAttribute.getAttribute("data-pagetype").toLowerCase();
    s.prop10 = s.getPreviousValue(s.pageName, "gpv_p10", "").toLowerCase();
    s.prop11 = encodeURI(window.location.href.replace(/%20/gi, "+"));
    s.prop25 = context.browseCountry.toLowerCase();
    s.prop31 = context.loginStatus;
    s.prop39 = analyticsConfig.elementsList;
    s.prop6 =
      s.getQueryParamValue("CTAref", true) !== ""
        ? s.getQueryParamValue("CTAref", true).toLowerCase()
        : "";

    const video = data.video;
    const analyticsTrackType =
      "video " + video.playState + " " + video.playOption;
    const videoData =
      video.videoTitle.toLowerCase() +
      "|" +
      video.playState +
      " " +
      video.playOption +
      "|" +
      video.pauseTime;

    s.events = "event117";
    s.eVar111 = videoData;
    s.prop40 = "video " + video.playState + "|click";
    s.events += ",event120";

    s.tl(true, "o", analyticsTrackType, analyticsOverrides);

    if (data.video.playOption === "pause") {
      window.removeEventListener(
        "tracking.home.pageInteraction",
        interactionListeners
      );
    }
  }
};

export const videoAnalytics = (analyticsConfig, customerId) => {
  interactionListeners = interactionHandler.bind(
    null,
    analyticsConfig,
    customerId
  );

  window.addEventListener(
    "tracking.home.pageInteraction",
    interactionListeners
  );
};
