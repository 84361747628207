import {
  addClickEventListener,
  buttonClickEvent,
} from "../../carousel/utils/analytics";
export const addAnalyticsToArticleFeed = (pageAnalyticsConfig) => {
  addClickEventListener(
    ".articleFeed .mu__cta, .articleFeed .viewAll a",
    (event) => {
      buttonClickEvent(
        "button click",
        "fashion and beauty feed",
        event.currentTarget.dataset.analyticsId,
        pageAnalyticsConfig
      );
    }
  );
};
