import { Accordion } from "./components/accordion";
import { Feature } from "./components/feature";
import { Hero } from "./components/hero";
import { ResponsiveImages } from "./components/images";
import { Moment } from "./components/moment";
import { lazyLoad } from "./utilities/lazy-load";
import { ApplyStyles } from "./utilities/apply-styles";
import { setDynamicTextColour } from "./utilities/set-dynamic-text-colour";
import { setDynamicBackgroundImage } from "./utilities/set-dynamic-background-image";
import { setDynamicBackgroundColor } from "./utilities/set-dynamic-background-color";
import { setButtonHoverColor } from "./utilities/set-button-hover-color";
import { ProductContainer } from "./components/product-container";
import { UGCGallery } from "./components/ugc-gallery";
import { setFullWidthContainer } from "./utilities/set-full-width-container";
import { VideoHero } from "./components/video-hero";
import { VideoClickToPlay } from "./components/video-click-to-play";
import { ProductCarousel } from "./components/product-carousel/product-carousel";
import { runOnSelected } from "./utilities/runOnSelected";
import { ProductCategories } from "./components/product-categories";
import { CarouselNav } from "./components/carousel/utils/carousel-nav";
import { setImage } from "./utilities/set-image";
import { ArticleFeed } from "./components/article-feed";
import { initAnalytics } from "./utilities/initAnalytics";
import { addAnalyticsToArticleFeed } from "./components/article-feed/utils/addAnalyticsToArticleFeed";
import { ArticleTile } from "./components/article-tile";
import { pageLoadAnalytics } from "./utilities/pageLoadAnalytics";
import debounce from "./utilities/debounce";
import { ArticleSidebar } from "./components/article-sidebar";
import { setupCriteo } from "./components/criteo";
import {
  DISPLAY_AD_MIDDLE_SELECTOR,
  DISPLAY_AD_BOTTOM_SELECTOR,
} from "./constants";

const stylesArray = [];

const pageAnalyticsConfig = initAnalytics(pageLoadAnalytics);

// HTML Generating components
runOnSelected(".productContainer", async (element) => {
  await ProductContainer(element);
  runOnSelected(".nav-dots", CarouselNav, element);
  runOnSelected(".js-buttonHover", setButtonHoverColor, element);
  lazyLoad();
});

// HTML Generating components
runOnSelected(".asomGallery", async (element) => {
  let currentLayoyout;

  const handleResizing = async () => {
    const w = window.innerWidth;

    const layout = w < 737 ? "mobile" : "desktop";

    if (currentLayoyout !== layout) {
      await UGCGallery(element, layout);
      runOnSelected(".nav-dots", CarouselNav, element);
      runOnSelected(".js-buttonHover", setButtonHoverColor, element);
      lazyLoad();
    }
    currentLayoyout = layout;
  };

  window.addEventListener("resize", debounce(handleResizing));
  handleResizing();
});

// Article Feed Component
runOnSelected(".js-articleFeed", async (element) => {
  await ArticleFeed(element);
  runOnSelected(".nav-dots", CarouselNav, element);
  runOnSelected(".js-buttonHover", setButtonHoverColor, element);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundImage);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundColor);
  lazyLoad();
});

addAnalyticsToArticleFeed(pageAnalyticsConfig);

// End of article feed

runOnSelected(".articleTile", async (element) => {
  await ArticleTile(element);
  runOnSelected(".nav-dots", CarouselNav, element);
  runOnSelected(".js-buttonHover", setButtonHoverColor, element);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundImage);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundColor);
  lazyLoad();
});

runOnSelected(".global-sidebar-stylefeed .articles", async (element) => {
  await ArticleSidebar(element);
  runOnSelected(".js-buttonHover", setButtonHoverColor, element);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundImage);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundColor);
  lazyLoad();
});

runOnSelected(".productCat", ProductCategories);
runOnSelected(".salesBanner", ProductCategories);

// JS Components
runOnSelected(".accordion .accordion-group .accordion-heading", Accordion);
runOnSelected(".accordion dl dt", Accordion);
runOnSelected(".feature", Feature);
runOnSelected(".hero", Hero);
runOnSelected(".js-responsive-image", ResponsiveImages);
runOnSelected(".moment", Moment);
runOnSelected(".js-dynamicText", setDynamicTextColour);
runOnSelected(".js-dynamicBackground", setDynamicBackgroundImage);
runOnSelected(".js-dynamicBackground", setDynamicBackgroundColor);
runOnSelected(".js-buttonHover", setButtonHoverColor);
runOnSelected(".js-fullWidthContainer", setFullWidthContainer);
runOnSelected(".js-videoBackground", VideoHero);
runOnSelected(".nav-dots", CarouselNav);
runOnSelected(".js-appendImage", setImage);
runOnSelected(DISPLAY_AD_MIDDLE_SELECTOR, setupCriteo);
runOnSelected(DISPLAY_AD_BOTTOM_SELECTOR, setupCriteo);

runOnSelected(".productCarousel__products", async (element, i) => {
  await ProductCarousel({
    element,
    index: "productCarousel-" + i,
  });

  runOnSelected(".nav-dots", CarouselNav, element);
  runOnSelected(".js-buttonHover", setButtonHoverColor, element);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundImage);
  runOnSelected(".js-dynamicBackground", setDynamicBackgroundColor);
  lazyLoad();
});

const videoClickToPlays = document.querySelectorAll(".js-videoClickToPlay");

for (let i = 0; i < videoClickToPlays.length; ++i) {
  const videoClickToPlay = videoClickToPlays[i];
  const videoPlayer = new VideoClickToPlay({
    wrapper: videoClickToPlay.parentNode,
    container: videoClickToPlay,
    srcPrimary: videoClickToPlay.getAttribute("data-srcPrimary"),
    srcSecondary: videoClickToPlay.getAttribute("data-srcSecondary"),
    posterPrimary: videoClickToPlay.getAttribute("data-posterPrimary"),
    posterSecondary: videoClickToPlay.getAttribute("data-posterSecondary"),
    errorMessage: videoClickToPlay.parentNode.querySelector(".js-videoError"),
    breakpoint: "(max-width: 767px)",
    classNames: "videoClickToPlay-player",
    playbackSettings: ["playsInline"],
    controls: {
      start: videoClickToPlay.parentNode.querySelector(".js-videoStart"),
      playPause: videoClickToPlay.querySelector(".js-videoPlayPause"),
      volume: videoClickToPlay.querySelector(".js-videoVolume"),
      fullScreen: videoClickToPlay.querySelector(".js-videoFullScreen"),
      progress: videoClickToPlay.querySelector(".js-videoProgress"),
      progressTime: videoClickToPlay.querySelector(".js-videoProgressTime"),
      progressDuration: videoClickToPlay.querySelector(
        ".js-videoProgressDuration"
      ),
      buffer: videoClickToPlay.querySelector(".js-videoBuffer"),
      progressBar: videoClickToPlay.querySelector(".js-videoProgressBar"),
    },
  });

  videoPlayer.init();
}

if (stylesArray.length > 0) {
  ApplyStyles({ stylesArray });
}

lazyLoad();
