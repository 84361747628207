export const setDynamicTextColour = (element) => {
  const defaultTextColour = element.style.color;
  const desktopTextColour = element.dataset.headlineDesktopTextColour;

  window.addEventListener("resize", applyStyles);

  function applyStyles() {
    const { innerWidth } = window;
    const isDesktop = innerWidth >= 736;
    const textColour = isDesktop ? desktopTextColour : defaultTextColour;

    element.style.color = textColour;
  }

  applyStyles();
};
