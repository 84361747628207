import configureLogger from "asos-web-logging";
import { configureRequest } from "@asosteam/asos-web-request";
import { handleApiData } from "./handleApiData";
import pkg from "../../../../package.json";

export const callApi = async (config) => {
  const logger = configureLogger({
    moduleName: "get-articles",
    maxLogLevel: "info",
  });
  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-web-ui-library",
      version: pkg.version,
      platform: "Web",
    },
  });

  try {
    const { data: dataSummaries } = await request.get({
      url: config.urlSummaries,
    });

    const { data: dataStockPrice } = await request.get({
      url: config.urlStockprice,
    });

    const data = dataSummaries.map(function (product) {
      const stockPrice = dataStockPrice.find(function (item) {
        return item.productId === product.id;
      });
      return Object.assign({}, product, stockPrice, {
        price: stockPrice.productPrice,
      });
    });

    return handleApiData(data, config);
  } catch (error) {
    console.log("Error making AJAX request: ", error);
    return [];
  }
};
