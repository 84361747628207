import { calculatePanelHeight } from "./calculatePanelHeight";

export const preparePanelHeight = (status) => {
  let prevWidth = window.innerWidth;

  const recalcPanelHeight = () => {
    if (prevWidth != window.innerWidth) {
      prevWidth = window.innerWidth;
      calculatePanelHeight(status);
    }
  };

  calculatePanelHeight(status);
  window.addEventListener("resize", recalcPanelHeight);
};
