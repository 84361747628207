import { analyticsEventTrigger } from "../../../utilities/analyticsEventTrigger";

export const buttonClickEvent = (
  context,
  carouselType,
  valueID,
  analyticsConfig
) => {
  analyticsEventTrigger(
    `tracking.${analyticsConfig.eventName}.carouselAction`,
    {
      context,
      valueID, // this value is retreived from the dom
      carouselType, // need to get this value from the dom
    }
  );
};

export const chevronEvent = (analyticsConfig, context) => {
  analyticsEventTrigger(
    `tracking.${analyticsConfig.eventName}.carouselAction`,
    {
      context: "chevron click",
      carouselType: context.el.get(0).getAttribute("data-carousel-name"), // need to get this value from the dom
      componentName: context.el.get(0).getAttribute("data-tracking-name"),
    }
  );
};

export const addClickEventListener = (query, func) => {
  const elements = document.querySelectorAll(query);

  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", func);
  }
};
