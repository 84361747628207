export const setFullWidthContainer = (element) => {
  let contentWidth = 1322;
  if (element.parentElement.parentElement.classList.contains("productCat-full-width")) {
    contentWidth = "100%";
  }

  window.addEventListener("resize", resizeHandler);
  resizeHandler();

  function resizeHandler() {
    const { innerWidth } = window;

    if (innerWidth > contentWidth) {
      setWidthToWindow(innerWidth);
    } else {
      setFullWidth();
    }
  }

  function setWidthToWindow(innerWidth) {
    let marginLeft;
    const notFullWidth =
      element.classList.contains("mu-section") ||
      element.classList.contains("grid-backgroundWrapper__row") ||
      element.classList.contains("js-scrollable-promo-banner__background");
    if (notFullWidth) {
      marginLeft = "auto";
    } else {
      marginLeft = (innerWidth - contentWidth) / -2;
    }

    element.style.marginLeft = `${marginLeft}px`;
    element.style.width = `${innerWidth}px`;
  }

  function setFullWidth() {
    element.style.width = "100%";
    element.style.marginLeft = "auto";
  }
};
