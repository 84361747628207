export const previousPrice = (item) =>
  item.price.isMarkedDown
    ? `<label class="product__price previous__price" aria-label="Original price ${item.price.previous.text}">${item.price.previous.text}</label>`
    : "";
export const currentPrice = (item) => `
  <label class="product__price ${
    item.price.isMarkedDown ? "sale" : ""
  }" aria-label="${`${item.price.isMarkedDown ? "sale" : ""} price ${
  item.price.current.text
}`}">${item.price.current.text}</label>`;
