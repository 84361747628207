import { pageRedirect } from "./utils/pageRedirect";
import { targetIsLink } from "./utils/targetIsLink";
import { updateBottomAlignment } from "./utils/updateBottomAlignment";

export const Hero = (element) => {
  const url = element.dataset.href;

  if (url) {
    element.addEventListener("click", (e) => {
      if (!targetIsLink(e.target)) {
        pageRedirect(element, e.currentTarget.getAttribute("data-href"));
      }
    });
  }

  document.addEventListener("resize", () => {
    updateBottomAlignment(element);
  });

  updateBottomAlignment(element);
};
