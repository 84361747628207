export const targetIsLink = (target) => {
  const elements = [];

  while (target) {
    if (target.nodeName === "A" || target.nodeName === "svg") {
      return true;
    }

    elements.unshift(target);
    target = target.parentNode;
  }

  return false;
};
