import { sdkProvider } from "./sdk-provider";

export const initAnalytics = (callback) => {
  window.require(["Analytics.Config"], (analyticsConfig) => {
    sdkProvider("identity").then((identity) => {
      identity.customer.customerId().then((customerId) => {
        return callback(analyticsConfig, customerId);
      });
    });
  });
};
