import { getArticlesFromLocalStorage } from "./getArticlesFromLocalStorage";
import { handleData } from "./handleData";
import { errorCallback } from "./errorCallback";
export const handleError = (element) => {
  const storedArticles = getArticlesFromLocalStorage();

  if (storedArticles.length > 0) {
    return handleData(storedArticles, element);
  }
  errorCallback();
  return null;
};
