import { createCarousel } from "../carousel/utils/createCarousel";
import { callApi } from "../article-feed/utils/callApi";
import { createArticleListItem } from "../article-feed/utils/createArticleListItem";
import { getArticlesFromTemplate } from "./utils/getArticlesFromTemplate";
import { removeElem } from "./utils/removeElem";

export const ArticleTile = async (element) => {
  let articles = [];
  const url = element.getAttribute("data-feed-url");

  articles = url
    ? await callApi(url, element)
    : getArticlesFromTemplate(element);

  const options = {
    maxPageSize: 4,
    listItemFunc: createArticleListItem(false, true),
    root: "carousel",
  };

  removeElem(".articleTile__buttons");
  removeElem(".articleTile__listWrapper");
  removeElem(".articleTile__progress");

  element.innerHTML += createCarousel(articles, options);
  element.querySelector(".carousel").classList.add("no-spin");
};
