export const observeDots = (carouselItems, func) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.querySelector("a").removeAttribute("tabindex");
        else entry.target.querySelector("a").setAttribute("tabindex", "-1");
        func(entry);
      });
    },
    {
      threshold: 0.5,
      rootMargin: "10000px 0px 10000px 0px",
    }
  );

  carouselItems.forEach((listItem) => {
    observer.observe(listItem);
  });
};
