import { createCarousel } from "../carousel/utils/createCarousel.js";
import { outOfStockMessages } from "./models/outOfStockMessages.js";
import { callApi } from "./utils/callApi.js";
import { extractProductIdList } from "./utils/extractProductIdList.js";
import { filterToOnlyColourProducts } from "./utils/filterToOnlyColourProducts.js";
import { generateApiUrl } from "./utils/generateApiUrl.js";

export const ProductCarousel = async ({ index, element }) => {
  const { additionalQueryStringParameters, productList, path, baseurl } =
    element.dataset;
  const productIdList = extractProductIdList(
    JSON.parse(productList.replace(/\s/g, ""))
  );
  const inArticlePages = element.closest(".article-container") !== null;
  if (inArticlePages && productIdList.length === 0) {
    element.closest(".productCarousel").parentElement.remove();
    return;
  }
  const siteChrome =
    window.asos.webContext && window.asos.webContext.getWebContext();
  const apiConfig = {
    index,
    path,
    baseurl,
    productIdList,
    productIdColourList: filterToOnlyColourProducts(productIdList),
    browseCountry: siteChrome.browseCountry,
    storeCode: siteChrome.storeId,
    currencyCode: siteChrome.browseCurrency,
    outOfStockMessage: outOfStockMessages,
    event: "ProductFeedLoaded",
    urlSummaries: generateApiUrl(
      "summaries",
      path,
      productIdList,
      siteChrome.storeId,
      `&expand=variants${additionalQueryStringParameters}`
    ),
    urlStockprice: generateApiUrl(
      "stockprice",
      path,
      productIdList,
      siteChrome.storeId,
      `&country=${siteChrome.browseCountry}&currency=${siteChrome.browseCurrency}`
    ),
  };
  const orderedProducts = await callApi(apiConfig);
  const options = {
    isArticlePage: false,
    prev: element.querySelectorAll(".carousel__controls")[0].dataset,
    next: element.querySelectorAll(".carousel__controls")[1].dataset,
    maxPageSize: inArticlePages ? 2 : 5,
    mobilePageSize: 2,
    previewNextPagePercentage: 0,
    type: "carousel",
    root: "carousel",
    rootAriaLabel: "Carousel",
  };
  element.innerHTML = createCarousel(orderedProducts, options);
  element.classList.add("no-spin");
};
