import React from "react";
import { createRoot } from "react-dom/client";
import { PLACEMENT } from "@asosteam/asos-web-adverts-sdk";
import { AdvertContainer } from "./advertContainer";
import { getCategoryForCriteo } from "./getCategoryForCriteo";
import { getAdvertSdkInstance } from "./getAdvertSdkInstance";
import {
  DISPLAY_AD_MIDDLE_SELECTOR,
  DISPLAY_AD_BOTTOM_SELECTOR,
} from "../../constants";

export const setupCriteo = (element, index, selector) => {
  const category = getCategoryForCriteo();

  if (category === null) {
    return;
  }

  const sdk = getAdvertSdkInstance();
  const root = createRoot(element);

  if (selector === DISPLAY_AD_MIDDLE_SELECTOR) {
    root.render(
      <AdvertContainer
        sdk={sdk}
        placement={PLACEMENT.MIDDLE}
        category={category}
      />
    );
  } else if (selector === DISPLAY_AD_BOTTOM_SELECTOR) {
    root.render(
      <AdvertContainer
        sdk={sdk}
        placement={PLACEMENT.BOTTOM}
        category={category}
      />
    );
  }
};
