export const getArticlesFromTemplate = (element) => {
  const articleElements = element
    .querySelector(".articleTile__list")
    .querySelectorAll(".articleTile__item");

  return [...articleElements].map((article) => {
    return {
      Title: article.querySelector(".articleTile__item__title").innerHTML,
      Franchise: article.querySelector(".articleTile__item__category")
        .innerHTML,
      ImageSource: article.querySelector(".articleTile__image").src,
      LinkUrl: article.querySelector("a").getAttribute("href"),
      OverviewText: article.querySelector(".articleTile__item__overview")
        .innerHTML,
    };
  });
};
