const storeCode = window.asos.webContext.getWebContext().storeId;

const srOnlyText = {
    "COM": "Use Next and Previous buttons to navigate, or jump to a page using the pagination dots when available",
    "AU": "Use Next and Previous buttons to navigate, or jump to a page using the pagination dots when available",
    "US": "Use Next and Previous buttons to navigate, or jump to a page using the pagination dots when available",
    "ROW": "Use Next and Previous buttons to navigate, or jump to a page using the pagination dots when available",
    "ROE": "Use Next and Previous buttons to navigate, or jump to a page using the pagination dots when available",
    "DE": "Verwende die Schaltflächen „Weiter“ und „Zurück“, um zu navigieren, oder springe mithilfe der Seitennummerierungspunkte zu einer Seite, sofern verfügbar.",
    "FR": "Utilisez les boutons Suivant et Précédent pour naviguer ou accédez à une page à l'aide des points de pagination lorsqu'ils sont disponibles",
    "IT": "Utilizzare i pulsanti Avanti e Indietro per spostarsi o passare a una pagina utilizzando i punti di impaginazione, se disponibili",
    "ES": "Utiliza los botones Siguiente y Anterior para navegar o los puntos de la paginación para dirigirte a una página en concreto cuando esté disponible",
    "NL": "Gebruik de knoppen Volgende en Vorige om te navigeren of ga naar een pagina met behulp van de paginapunten, indien beschikbaar",
    "DK": "Brug knapperne Næste og Forrige til at navigere, eller spring direkte til en side ved hjælp af pagineringspunkterne, når de er tilgængelige",
    "SE": "Använd knapparna Nästa och Föregående för att navigera, eller hoppa till en sida med hjälp av sidnumrering när det finns tillgängligt",
    "PL": "Użyj przycisków Dalej i Wstecz, aby wygodnie nawigować pomiędzy stronami, lub przejdź do strony za pomocą punktów paginacji, jeśli są dostępne"
};

export const carouselInstructions = srOnlyText[storeCode];
