import debounce from "../../utilities/debounce";

export const VideoHero = (element) => {
  const { posterprimary, postersecondary, srcprimary, srcsecondary } = element.dataset;
  let video;
  let { innerWidth: currentInnerWidth } = window;

  function insertVideo() {
    video = document.createElement("video");
    video.controls = true;
    video.loop = true;
    video.muted = true;
    video.playsInline = true;

    element.appendChild(video);
  }

  function setPosterAndSource() {
    const { innerWidth } = window;

    const isMobile = innerWidth < 767;
    const src = isMobile && srcsecondary ? srcsecondary : srcprimary;
    const poster = isMobile && postersecondary ? postersecondary : posterprimary;

    if(poster) video.poster = poster;
    if(src) video.src = src;
  }

  function handleResize() {
    const { innerWidth } = window;
    if(currentInnerWidth !== innerWidth) {
      setPosterAndSource();
      currentInnerWidth = innerWidth;
    }
  }

  function canAutoPlay() {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    const isChromium = !!window.chrome;

    if(prefersReducedMotion) return false;
    if(isChromium && (navigator.connection.saveData || navigator.connection.effectiveType === "2g")) return false;

    return true;
  }

  window.addEventListener("resize", debounce(handleResize));

  insertVideo();
  setPosterAndSource();

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        if(canAutoPlay()) {
          video.play();
        }
      } else {
        video.pause();
      }
    });
  }, {
    rootMargin: "0px",
  });

  observer.observe(element);
};
