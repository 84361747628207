import { sdk } from "@asosteam/asos-web-adverts-sdk";
import { sdkProvider } from "../../utilities/sdk-provider";
import { isProductionEnvironment } from "./isProductionEnvironment";

let sdkInstance = null;

export const getAdvertSdkInstance = () => {
  if (sdkInstance === null) {
    const { browseCountry, browseCurrency, platform } =
      window.asos.webContext.getWebContext();

    sdkInstance = sdk({
      production: isProductionEnvironment(),
      webContextDelegate: {
        browseCountry,
        browseCurrency,
        platform,
      },
      identityDelegatePromise: sdkProvider("identity"),
      featuresDelegatePromise: sdkProvider("features"),
    });
  }

  return sdkInstance;
};
