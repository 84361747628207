export const formatDate = (pubDate) => {
  const slicedPubDate = slicePubDate(pubDate);
  const isoDate = formatIsoDate(slicedPubDate);

  const dateObject = new Date(isoDate);
  const localeDate = createLocaleDate(dateObject);

  return localeDate;
};

const slicePubDate = (date) => {
  return date.slice(0, 8);
};

const formatIsoDate = (date) => {
  const firstSeparation = [date.slice(0, 6), "/", date.slice(6)].join("");

  return [firstSeparation.slice(0, 4), "/", firstSeparation.slice(4)].join("");
};

const createLocaleDate = (dateObject, locale) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return dateObject.toLocaleDateString(
    window.asos.webContext.getBrowseLanguage() || "en-GB",
    options
  );
};
