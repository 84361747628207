import { currentPrice, previousPrice } from "./carouselPrices";
import { setResponsiveImage } from "../../../utilities/set-image";
import { setAriaLabel } from "../utils/setAriaLabel";

export const createCarouselListItem = (item, index) => {
  item.ariaLabel = setAriaLabel(item);
  const { productUrl, ariaLabel, imageUrl } = item;
  const hostname = JSON.parse(
    document.querySelector(
      'script[type="application/json"][data-id="window.asos.siteChrome._initialStore"]'
    ).textContent
  ).regionalStore.storeUrls.default;
  return `
  <li class="carousel__item" id="product_${index}">
    <a
      href="${productUrl ? productUrl : `${hostname}${item.url}`}"
      target="_self"
      rel="nofollow"
      aria-label="${ariaLabel ? ariaLabel : `${item.ariaLabel}`}"
    >
      <div class="carousel__image__group">
      ${setResponsiveImage({
    imageUrl,
  })}
      </div>
      <div class="carousel__label">
        <label class="product__name">${item.name}</label>
        <span>
          ${previousPrice(item)}
          ${currentPrice(item)}
        </span>
      </div>
    </a>
  </li>
  `;
};
